import axios from "axios";
import { BASE_URL, TIME_OUT } from "@/config/env";
import type {
  AxiosPromise,
  AxiosRequestConfig,
  AxiosResponse,
  AxiosInstance,
  AxiosError
} from "axios";
import { message } from "ant-design-vue";
import router from "@/router";
import { useStore } from "@/store";

export interface IResponseData<T = any> {
  code: number;
  msg: string;
  data: T;
}
let isPurview = false;

type requestMethods = (
  url: string,
  params?: any,
  config?: AxiosRequestConfig
) => Promise<IResponseData<any>>;

interface IService {
  get: requestMethods;
  post: requestMethods;
  put: requestMethods;
  delete: requestMethods;
  download: requestMethods;
  /* http: AxiosInstance;
  addInterceptors: (instance: AxiosInstance) => void;
  request: (p: AxiosPromise) => Promise<IResponseData<any>>; */
}

class HttpService implements IService {
  private http: AxiosInstance;

  constructor(config: AxiosRequestConfig) {
    this.http = axios.create(config);
    this.addInterceptors(this.http);
  }

  get<T>(url: string, config?: AxiosRequestConfig) {
    return this.request<T>(this.http.get(url, config));
  }

  post<T>(url: string, params?: unknown, config?: AxiosRequestConfig) {
    return this.request<T>(this.http.post(url, params, config));
  }

  put<T>(url: string, params?: unknown, config?: AxiosRequestConfig) {
    return this.request<T>(this.http.put(url, params, config));
  }

  delete<T>(url: string, params?: unknown, config?: AxiosRequestConfig) {
    return this.request<T>(this.http.delete(url, { data: params, ...config }));
  }

  download<T>(url: string, params?: unknown) {
    return this.request<T>(
      this.http.post(url, params, {
        responseType: "arraybuffer",
        headers: {
          "Content-Type": "application/json"
        }
      })
    );
  }

  private addInterceptors(axios: AxiosInstance) {
    // 请求拦截
    axios.interceptors.request.use((config) => {
      // 请求带上token
      const store = useStore();
      if (store.getToken && config.headers) {
        config.headers["Authorization"] = store.getToken;
      }
      if (config.method === "get" && config.responseType === "blob") {
        config.data = true;
        config.headers!["Content-Type"] = "application/x-www-form-urlencoded";
      }
      return config;
    });

    // 响应拦截
    axios.interceptors.response.use(
      (response: AxiosResponse<IResponseData>) => {
        switch (response.data.code) {
          case 2002:
            if (!isPurview) {
              message.config({
                maxCount: 1,
                duration: 2
              });
              message
                .error("用户信息过期, 请重新登录！")
                .then(() => {
                  isPurview = false
                  router.push("/login"); 
                });
            }
            isPurview = true;
            break;
          case 2003:
            message.error("用户不存在，请联系管理员");
            break;
          case 500:
            message.error(response.data.msg);
            break;
          case 400:
            message.warn(response.data.msg);
            break;
          case 405:
            message.warn(response.data.msg);
            break;
        }
        return response;
      },
      (error: AxiosError<IResponseData>) => {
        const res = error.response?.data;
        // 有响应的情况, 抛出接口的msg
        if (res) {
          switch (res.code) {
            case 401:
              message
                .error("用户信息过期, 请重新登录！")
                .then(() => router.push("/login"));
              break;
            case 403:
              message.warn(res.msg);
              break;
            case 500:
              message.error(res.msg);
              break;
            case 400:
              message.warn(res.msg);
              break;
            default:
              res.msg && message.error(res.msg);
          }
        } else {
          // 没有响应的情况下, 报网络错误
          message.error("网络错误, 请稍后再试!");
        }
        return Promise.reject(error);
      }
    );
  }

  private async request<T>(p: AxiosPromise): Promise<IResponseData<T>> {
    return p
      .then((response) => {
        if (response.headers["content-type"].includes("application/json")) {
          return response.data;
        }
        return response;
      })
      .catch((error: AxiosError) => {
        // message.error("网络错误,请稍后重试!");
        return error.response?.data;
      });
  }
}

export default new HttpService({
  baseURL: BASE_URL,
  timeout: TIME_OUT
});
